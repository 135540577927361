import Button from "@ingka/button"
import { useAppDispatch } from "app/hooks"
import { useNavigate } from "react-router-dom"
import { toggleFilterMenu } from "features/listConversations/listConversationSlice"

const FilterButton = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <Button
      small={true}
      type="secondary"
      onClick={() => {
        navigate("/")
        setTimeout(() => {
          dispatch(toggleFilterMenu())
        }, 50)
      }}
    >
      Edit selection
    </Button>
  )
}

export default FilterButton
