import { useAppDispatch } from "app/hooks"
import {
  removeMessage,
  resetInsights,
  setAdvancedControlsSheetVisible,
  setAnimateButton,
  setTabIndex,
} from "features/insights/insightsSlice"
import { ToolbarProps } from "features/insights/types"

const InsertedDataToolbar = ({ disabled, hasAppliedInitialData }: ToolbarProps) => {
  const dispatch = useAppDispatch()

  return (
    <div className="flex-row message-toolbar">
      <div className="flex-row message-toolbar">
        <button
          disabled={disabled}
          onClick={() => {
            dispatch(setAdvancedControlsSheetVisible(true))
            dispatch(setAnimateButton(true))
          }}
        >
          Reconfigure data
        </button>
        <button
          disabled={disabled}
          onClick={() => {
            dispatch(removeMessage(0))
            dispatch(setTabIndex(0))
            dispatch(resetInsights())
            hasAppliedInitialData && (hasAppliedInitialData.current = false)
          }}
        >
          Use default data (and reset the chat)
        </button>
      </div>
    </div>
  )
}

export default InsertedDataToolbar
