import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setAdvancedControlsSheetVisible } from "features/insights/insightsSlice"
import Button from "@ingka/button"

const HideAdvancedButton = () => {
  const dispatch = useAppDispatch()
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const insightsState = useAppSelector((state: RootState) => state.insights)

  const inputDisabled = !conversations || insightsState.insightsLoading || insightsState.insightsSummaryLoading

  return (
    <div>
    <Button
      type="secondary"
      disabled={inputDisabled}
      onClick={() => dispatch(setAdvancedControlsSheetVisible(false))}
      style={{width: "100%"}}
    >
      Hide
    </Button>
    </div>
  )
}

export default HideAdvancedButton
