import Modal, { Sheets, ModalBody } from "@ingka/modal"
import { SheetViewProps } from "features/insights/types"

const SheetView: React.FC<SheetViewProps> = ({ visible, handleClose, content, footer = null, details }) => {
  return (
    <div className="sheet-parent">
      <Modal
        visible={visible}
        handleCloseBtn={handleClose}
        escapable={true}
      >
        <Sheets
          labelledById="sheetView"
          alignment="left" /* left | right */
          preserveAlignment={false} /* affects mobile view only */
          size="medium" /* small | medium | large */
          footer={footer}
          header={<></>}
        >
          <ModalBody
            style={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100vh - 3rem)",
            }}
          >
            <div className="sheet-title"></div>
            {content}
          </ModalBody>
        </Sheets>
        {details}
      </Modal>
    </div>
  )
}

export default SheetView
