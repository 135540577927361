import FeatureTitle from "components/FeatureTitle"

const HistoryView = () => {
  return (
    <div style={{ position: "relative", height: "inherit" }}>
      <div className="controls-parent">
        <FeatureTitle featureName="Chat history" />
      </div>
    </div>
  )
}

export default HistoryView
