import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import InsightsTable from "features/insights/InsightsTable"
import { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"

const AdvancedViewDetails = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const [visibilityState, setVisibilityState] = useState<string>("collapsed") // Options: 'collapsed', 'slightlyVisible', 'visible'

  const visible = insightsState.advancedControlsSheetVisible

  useEffect(() => {
    insightsState.insightsLoading
      ? setVisibilityState("slightlyVisible")
      : Object.keys(insightsState.insights).length !== 0
        ? setVisibilityState("visible")
        : setVisibilityState("collapsed")
  }, [insightsState])

  return (
    <CSSTransition
      in={visible}
      timeout={250}
      classNames="slide"
      unmountOnExit
    >
      <div className={`modal-details-panel-parent ${visibilityState}`}>
        <div className="overflow-hider">
          <div className="modal-details-panel">
            <InsightsTable />
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default AdvancedViewDetails
