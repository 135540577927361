import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import {
  appendMessage,
  popMessages,
  resetMessages,
  setCurrentMessage,
  setMessageEditIndex,
} from "../insightsSlice"
import {
  generateDefaultData,
  updateInsights,
  updateInsightsSummary,
  updateLLMChat,
} from "./llmUtils"
import { Message } from "../types"

export const InsightsUtilsRunners = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const runInsights = async () => {
    if (!insightsState.selectedInsightPrompt.prompt || !conversations) return

    runCancelEdit()
    dispatch(resetMessages())

    updateInsights(insightsState.selectedInsightPrompt, conversations, dispatch)
  }

  const runSummary = () => {
    if (!insightsState.selectedSummaryPrompt.prompt) return

    updateInsightsSummary(
      insightsState.selectedSummaryPrompt,
      insightsState.insights,
      dispatch,
    )
  }

  const runChatMessage = async () => {
    if (!insightsState.currentChatMessage.prompt) return

    const sentMessage: Message = {
      content: insightsState.currentChatMessage.prompt,
      type: "sent",
    }

    insightsState.messageEditIndex
      ? runChatEdit(sentMessage)
      : updateLLMChat(
          [...insightsState.chatMessages, sentMessage],
          dispatch,
        )

    dispatch(setCurrentMessage(""))
    dispatch(appendMessage(sentMessage))
    runCancelEdit()
  }

  const runChatEdit = (latestMessage: Message) => {
    if (!insightsState.messageEditIndex) return

    const messagesToPost = [
      ...insightsState.chatMessages.slice(0, insightsState.messageEditIndex),
      latestMessage,
    ]

    updateLLMChat(messagesToPost, dispatch)
    dispatch(popMessages(insightsState.messageEditIndex))
  }

  const runEditMessage = (messageIndex: number) => {
    dispatch(
      setCurrentMessage(insightsState.chatMessages[messageIndex].content),
    )
    dispatch(setMessageEditIndex(messageIndex))
  }

  const runCancelEdit = () => {
    dispatch(setCurrentMessage(""))
    dispatch(setMessageEditIndex(null))
  }

  const runDefaultData = (
    hasAppliedInitialData: React.MutableRefObject<boolean>,
  ) => {
    if (conversations) {
      hasAppliedInitialData.current = true

      const defaultDataObject: Message = {
        content: generateDefaultData(conversations),
        type: "default-sample",
      }

      dispatch(appendMessage(defaultDataObject))
    }
  }

  return {
    runInsights,
    runSummary,
    runChatMessage,
    runEditMessage,
    runCancelEdit,
    runDefaultData,
  }
}
