import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import Pill from "@ingka/pill"

interface FiltersProps {
  button?: React.ReactNode
}

const SPACE_CHAR = " "
const UNDERSCORE = "_"
const COLON = ": "

const Filters: React.FC<FiltersProps> = ({ button }) => {
  const { filters } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  return (
    <div className="flex-row wrapped">
      {filters.map(({ key, value }, index) => {
        const capitalizedKey = key
          .split(UNDERSCORE)
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(SPACE_CHAR)
        const label = capitalizedKey + COLON + value

        return <Pill key={index} label={label} size="xsmall" />
      })}
      {button}
    </div>
  )
}

export default Filters
