import Button from "@ingka/button"
import { CircularProgress } from "@mui/material"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { InsightsUtilsRunners } from "../../utils/llmUtilRunners"

const SummaryButton = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { runSummary } = InsightsUtilsRunners()

  return (
    <Button
      disabled={
        insightsState.insightsSummaryLoading ||
        Object.keys(insightsState.insights).length === 0
      }
      onClick={runSummary}
      type="primary"
      small={true}
    >
      {insightsState.insightsSummaryLoading ? (
        <CircularProgress color="primary" />
      ) : (
        "Get summary"
      )}
    </Button>
  )
}

export default SummaryButton
