import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { InsightsUtilsRunners } from "../../utils/llmUtilRunners"
import { removeMessage } from "../../insightsSlice"
import { ToolbarProps } from "features/insights/types"

const SentMessageToolbar = ({ messageIndex, disabled }: ToolbarProps) => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { runEditMessage, runCancelEdit } = InsightsUtilsRunners()

  return (
    <div className="flex-row message-toolbar">
      {insightsState.messageEditIndex !== null &&
      insightsState.messageEditIndex === messageIndex ? (
        <button disabled={disabled} onClick={() => runCancelEdit()}>
          Cancel edit
        </button>
      ) : (
        <>
          <button
            disabled={disabled}
            onClick={() => messageIndex && runEditMessage(messageIndex)}
          >
            Edit
          </button>
          <button
            disabled={disabled}
            onClick={() => messageIndex && dispatch(removeMessage(messageIndex))}
          >
            Remove
          </button>
        </>
      )}
    </div>
  )
}

export default SentMessageToolbar
