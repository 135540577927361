import { Grid, Skeleton, TextField } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setCurrentMessage, setPrompt } from "./insightsSlice"
import he from "he"
import { ReactElement } from "react"

interface PromptInputProps {
  type: "insight" | "summary" | "chat"
  displayCost?: ReactElement | undefined
}

const PromptInput: React.FC<PromptInputProps> = ({ type, displayCost }) => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)

  const selectedPrompt =
    type === "insight"
      ? insightsState.selectedInsightPrompt
      : type === "summary"
        ? insightsState.selectedSummaryPrompt
        : type === "chat"
          ? insightsState.currentChatMessage
          : insightsState.selectedSummaryPrompt

  const inputDisabled =
    insightsState.insightsLoading || insightsState.insightsSummaryLoading

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        {insightsState.promptsLoading ? (
          <Skeleton variant="rectangular" width="100%" height={244} />
        ) : (
          <>
            <div style={{ position: "relative" }}>
              <TextField
                id="prompt-textarea"
                disabled={inputDisabled}
                multiline
                fullWidth
                minRows={type === "chat" ? 5 : 10}
                value={he.decode(selectedPrompt.prompt)}
                onChange={(e) => {
                  if (type === "insight" || type === "summary") {
                    dispatch(
                      setPrompt({
                        prompt: he.encode(e.target.value),
                        promptType: type,
                      }),
                    )
                  } else {
                    dispatch(setCurrentMessage(he.encode(e.target.value)))
                  }
                }}
              />
            </div>
            <div
              style={{
                flexGrow: "1",
                textAlign: "right",
                marginTop: "-2rem",
                marginRight: ".875rem",
                position: "relative",
                zIndex: "2",
                paddingBottom: "1rem",
              }}
            >
              {displayCost}
            </div>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default PromptInput
