import { Dispatch } from "redux"
import {
  fetchConversationInsights,
  fetchInsightsSummary,
} from "../insightsSlice"
import { ConversationInsight, SavedPrompt, Message } from "../types"
import { IConversation } from "app/types"

const DEFAULT_DATA_SIZE = 500

export const generateDefaultData = (conversations: IConversation[]): string => {
  let finalMessage = ""

  for (let i = 0; i < conversations.length; i++) {
    const conversation = conversations[i]
    let message = ""

    if (conversation.csatFeedback) {
      message += `<customer feedback>: <${conversation.csatFeedback.feedback}> \\n`
    }

    for (const mess of conversation.messages) {
      message += `<${mess.userType}>: <${mess.text ?? mess.textRaw}> \\n`
    }

    const tempMessageObj = message.slice(0, DEFAULT_DATA_SIZE)
    finalMessage += tempMessageObj
  }

  return finalMessage
}

export const updateInsights = async (
  prompt: SavedPrompt,
  conversations: any[],
  dispatch: Dispatch<any>,
  labelId?: number,
) => {
  if (!prompt?.prompt) return

  const maxIterations = Math.min(prompt.amount, conversations.length)
  const messages: Record<string, string> = {}
  for (let i = 0; i < maxIterations; i++) {
    const conversation = conversations[i]
    let message = ""
    if (conversation.csatFeedback) {
      message += `<customer feedback>: <${conversation.csatFeedback.feedback}> \\n`
    }
    for (const mess of conversation.messages) {
      message += `<${mess.userType}>: <${mess.text ?? mess.textRaw}> \\n`
    }
    messages[conversation.id] = message
  }
  if (labelId) {
    dispatch(
      fetchConversationInsights({
        messages,
        labelId: labelId,
        promptId: prompt.id,
      }),
    )
    return
  }
  dispatch(
    fetchConversationInsights({
      messages,
      systemPrompt: prompt.prompt,
      version: prompt.model,
    }),
  )
}

export const updateInsightsSummary = (
  prompt: SavedPrompt,
  insights: Record<string, ConversationInsight>,
  dispatch: Dispatch<any>,
  labelId?: number,
) => {
  if (!prompt?.prompt) return
  const insightsValues = Object.values(insights).map(
    (i: any) => "<" + i.insights + ">",
  )

  if (labelId) {
    dispatch(
      fetchInsightsSummary({
        insights: insightsValues,
        labelId: labelId,
        promptId: prompt.id,
      }),
    )
    return
  }
  dispatch(
    fetchInsightsSummary({
      insights: insightsValues,
      version: prompt.model,
      systemPrompt: prompt.prompt,
    }),
  )
}

export const updateLLMChat = (
  insights: Message[],
  dispatch: Dispatch<any>,
) => {
  // Default configuration
  const defaultConfig = {
    instructions: {
      received: `<This is you, the LLM model, one of your previously given answers to me>: <${JSON.stringify("PLACEHOLDER")}>`,
      sent: {
        latest: `<This is the latest instruction sent from me, the user to be focused on in your answer>: <${JSON.stringify("PLACEHOLDER")}>`,
        previous: `<This is a previous instruction sent from me, the user>: <${JSON.stringify("PLACEHOLDER")}>`,
      },
      data: `<This is a list of insights extracted from a sample of support conversations from our system>: <${JSON.stringify("PLACEHOLDER")}>`,
    },
    systemPrompt: "Not defined",
  }

  const configKey = "llmChatConfig"
  const storedConfig = localStorage.getItem(configKey)
  const config = storedConfig ? JSON.parse(storedConfig) : defaultConfig

  if (!storedConfig) {
    localStorage.setItem(configKey, JSON.stringify(defaultConfig))
  }

  const insightsValues = Object.values(insights).map(
    (i: any, index: number, array: any[]) => {
      if (i.type === "received") {
        return config.instructions.received.replace(
          JSON.stringify("PLACEHOLDER"),
          JSON.stringify(i),
        )
      } else if (i.type === "sent") {
        if (index === array.length - 1) {
          return config.instructions.sent.latest.replace(
            JSON.stringify("PLACEHOLDER"),
            JSON.stringify(i),
          )
        }
        return config.instructions.sent.previous.replace(
          JSON.stringify("PLACEHOLDER"),
          JSON.stringify(i),
        )
      } else if (i.type === "data" || i.type === "default-sample") {
        return config.instructions.data.replace(
          JSON.stringify("PLACEHOLDER"),
          JSON.stringify(i),
        )
      } else {
        return ""
      }
    },
  )

  // For logging purposes
  console.log(insightsValues)

  dispatch(
    fetchInsightsSummary({
      insights: insightsValues,
      version: "GPT-4o",
      systemPrompt: config.systemPrompt,
    }),
  )
}
