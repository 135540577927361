import { FC, ReactElement } from "react"
import { MessageType } from "./types"
import MarkdownPreview from "@uiw/react-markdown-preview"
import rehypeSanitize from "rehype-sanitize"

interface InsightsMessageProps {
  messageContent: string
  messageEditState: boolean | null
  type: MessageType
  toolbar: JSX.Element | null
  button: ReactElement
}

const InsightsMessage: FC<InsightsMessageProps> = ({
  messageContent,
  messageEditState,
  type,
  toolbar,
  button,
}) => {
  const rehypePlugins = [rehypeSanitize]

  const insightsMessageParent = {
    width: "80%",
    transition: "opacity 0.3s ease",
    ...(type === "received"
      ? { alignSelf: "flex-start" }
      : { alignSelf: "flex-end" }),
    ...(messageEditState && { opacity: ".15" }),
    ...(type !== "received" && { maxWidth: "40rem" }),
  }

  const inputMessage = {
    backgroundColor: "#f0f0f0",
    padding: "1rem",
    borderRadius: "1rem",
    alignSelf: "flex-end",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }

  const outputMessage = {
    backgroundColor: "transparent",
    alignSelf: "flex-start",
    padding: ".5rem 0",
  }

  if (type === "sent" || type === "received") {
    return (
      <div style={insightsMessageParent}>
        <div style={type === "sent" ? inputMessage : outputMessage}>
          <MarkdownPreview
            source={messageContent}
            rehypePlugins={rehypePlugins}
            style={{ backgroundColor: "transparent" }}
          />
        </div>
        {toolbar}
      </div>
    )
  } else if (type === "data") {
    return (
      <div style={insightsMessageParent}>
        <div style={inputMessage}>
          <div style={{ fontSize: "calc(15.5rem / 16" }}>
            *Processed data inserted*
            <br />
            <label
              className="skapa-label"
              style={{ margin: "0", marginTop: ".125rem" }}
            >
              Insights generated from the sampled conversations.
            </label>
          </div>
          {type === "data" && button}
        </div>
        {toolbar}
      </div>
    )
  } else return null
}

export default InsightsMessage
