import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import InsightsMessage from "features/insights/InsightsMessage"
import SentMessageToolbar from "../toolbars/SentMessageToolbar"
import ReceivedMessageToolbar from "../toolbars/ReceivedMessageToolbar"
import DefaultDataToolbar from "../toolbars/DefaultDataToolbar"
import InsertedDataToolbar from "../toolbars/InsertedDataToolbar"
import ViewInsightsButton from "../buttons/ViewInsightsButton"
import { ChatViewProps } from "features/insights/types"
import { useEffect, useState } from "react"

const ChatView = ({
  chatControlsRef,
  hasAppliedInitialData,
}: ChatViewProps) => {
  const insightsState = useAppSelector((state: RootState) => state.insights)

  const llmChatPadding = {
    paddingBottom: chatControlsRef.current
      ? `calc(${chatControlsRef.current.clientHeight}px + 5rem)`
      : "0px",
  }

  const noMessagesHeight = {
    minHeight: "24rem",
    height: chatControlsRef.current
      ? `calc(100vh - (${chatControlsRef.current.clientHeight}px + 11rem))`
      : "0px",
  }

  const sampleTexts = [
    "Generate an actionable summary",
    "Compile all customer satisfaction scores in a table",
    "Give me the conversations with the most critical issues",
  ]

  const [sampleTextIndex, setSampleTextIndex] = useState(0)
  const [fadeState, setFadeState] = useState(true)

  useEffect(() => {
    const switchText = () => {
      setFadeState(false)
      setTimeout(() => {
        setSampleTextIndex((prevIndex) => (prevIndex + 1) % sampleTexts.length)
        setFadeState(true)
      }, 500)
    }

    const currentText = sampleTexts[sampleTextIndex]
    const dynamicInterval = currentText.length * 75

    const minInterval = 3000
    const maxInterval = 10000
    const finalInterval = Math.min(
      Math.max(dynamicInterval, minInterval),
      maxInterval,
    )

    const interval = setInterval(switchText, finalInterval)
    return () => clearInterval(interval)
  }, [sampleTextIndex, sampleTexts.length])

  const noMessages = (
    <div className="no-messages" style={noMessagesHeight}>
      <div
        className="no-messages-label-parent"
        style={{ marginTop: "auto", paddingRight: "1rem" }}
      >
        <div
          className="no-messages-title"
          style={{
            opacity: fadeState ? 1 : 0,
          }}
        >
          "{sampleTexts[sampleTextIndex]}"
        </div>
      </div>
      <div className="no-messages-label-parent" style={{ marginTop: "auto" }}>
        <div style={{ maxWidth: "30rem", float: "left" }}>
          <label className="skapa-label">
            <span>Recommendation:</span> Start by asking questions about the
            sampled conversations, or open the advanced insight controls for
            more granular control over the processing done to the sampled raw
            data.
          </label>
        </div>
      </div>
    </div>
  )

  const showNoMessagesContent =
    insightsState.chatMessages.length === 0 ||
    (insightsState.chatMessages.length === 1 &&
      insightsState.chatMessages[0]?.type === "default-sample")

  const isMessageInEditedBranch = (messageIndex: number) => {
    return Boolean(
      insightsState.messageEditIndex &&
        messageIndex > insightsState.messageEditIndex,
    )
  }

  const toolbarDisabled = (messageIndex: number) => {
    return Boolean(
      insightsState.insightsSummaryLoading ||
        (insightsState.messageEditIndex &&
          insightsState.messageEditIndex < messageIndex),
    )
  }

  return (
    <div className="chat" style={llmChatPadding}>
      {showNoMessagesContent
        ? noMessages
        : insightsState.chatMessages.map((message, messageIndex) => (
            <InsightsMessage
              messageContent={message.content}
              messageEditState={isMessageInEditedBranch(messageIndex)}
              type={message.type}
              toolbar={
                message.type === "sent" ? (
                  <SentMessageToolbar
                    messageIndex={messageIndex}
                    disabled={toolbarDisabled(messageIndex)}
                  />
                ) : message.type === "received" ? (
                  <ReceivedMessageToolbar
                    messageIndex={messageIndex}
                    disabled={toolbarDisabled(messageIndex)}
                  />
                ) : message.type === "data" ? (
                  <InsertedDataToolbar
                    disabled={toolbarDisabled(messageIndex)}
                    hasAppliedInitialData={hasAppliedInitialData}
                  />
                ) : message.type === "default-sample" ? (
                  <DefaultDataToolbar
                    disabled={toolbarDisabled(messageIndex)}
                  />
                ) : null
              }
              button={<ViewInsightsButton />}
              key={messageIndex}
            />
          ))}
    </div>
  )
}

export default ChatView
