import { useAppDispatch } from "app/hooks"
import { removeMessage } from "../../insightsSlice"
import { ToolbarProps } from "features/insights/types"

const ReceivedMessageToolbar = ({ messageIndex, disabled }: ToolbarProps) => {
  const dispatch = useAppDispatch()

  return (
    <div className="flex-row message-toolbar" style={{ marginTop: "-.5rem" }}>
      <button
        disabled={disabled}
        onClick={() => messageIndex && dispatch(removeMessage(messageIndex))}
      >
        Remove answer
      </button>
    </div>
  )
}

export default ReceivedMessageToolbar
