import Button from "@ingka/button"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setAdvancedControlsSheetVisible } from "features/insights/insightsSlice"

const ShowAdvancedButton = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)

  if (insightsState.messageEditIndex === null) return (
      <Button
        onClick={() => dispatch(setAdvancedControlsSheetVisible(true))}
        type="secondary"
        small={true}
      >
        Insights fine-tuning
      </Button>
  )
}

export default ShowAdvancedButton
