import Filters from "features/listConversations/Filters"
import InsightsControls from "../../InsightsControls"
import FilterButton from "../buttons/FilterButton"
import PromptInput from "features/insights/PromptInput"
import { PromptControlProps } from "features/insights/types"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import FeatureTitle from "components/FeatureTitle"

const AdvancedView = ({ promptCrud }: PromptControlProps) => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const advancedControlsDisabled = !insightsState.advancedControlsVisible

  return (
    <div style={{ position: "relative", height: "inherit" }}>
      <div
        className={`${advancedControlsDisabled ? "unfocused-content controls-parent" : "controls-parent"}`}
      >
        <FeatureTitle featureName="Insights fine-tuning" />
        <div className="component-scaler" style={{ margin: "-.25rem 0" }}>
          <InsightsControls promptType={"insight"} />
        </div>
        <div>
          <label className="skapa-label">Sample selection</label>
          <div style={{ marginLeft: "-.125rem" }}>
            <Filters button={<FilterButton />} />
          </div>
        </div>
        <div>
          <PromptInput
            type="insight"
            // displayCost={displayCost(insightsCost, insightsState.selectedInsightPrompt.model)}
          />
          {promptCrud}
        </div>
      </div>
    </div>
  )
}

export default AdvancedView
