import Button from "@ingka/button"
import { CircularProgress } from "@mui/material"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { InsightsUtilsRunners } from "../../utils/llmUtilRunners"
import { useEffect, useState } from "react"

const InsightsButton = () => {
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { runInsights } = InsightsUtilsRunners()

  const inputDisabled =
    !conversations ||
    insightsState.insightsLoading ||
    insightsState.insightsSummaryLoading

  const [label, setLabel] = useState<string>("")

  useEffect(() => {
      insightsState.chatMessages[0]?.type === "data"
        ? setLabel("Reconfigure insights")
        : setLabel("Generate insights")
  }, [insightsState])

  return (
    <>
      <div
        className={`${insightsState.animateButton && insightsState.advancedControlsVisible ? "animate" : ""}`}
        style={{ width: "100%" }}
      >
        <Button type="primary" disabled={inputDisabled} onClick={runInsights} style={{width: "100%"}}>
          {insightsState.insightsLoading ? (
            <CircularProgress color="primary" />
          ) : (
            label
          )}
        </Button>
      </div>
    </>
  )
}

export default InsightsButton
