import { useAppDispatch } from "app/hooks"
import { setAnimateButton, setTabIndex } from "features/insights/insightsSlice"

const DefaultDataToolbar = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useAppDispatch()

  return (
    <div className="flex-row message-toolbar">
      <button
        disabled={disabled}
        onClick={() => {
          dispatch(setTabIndex(0))
          dispatch(setAnimateButton(true))
        }}
      >
        Manually configure
      </button>
    </div>
  )
}

export default DefaultDataToolbar
