import Button from "@ingka/button"
import { useAppDispatch } from "app/hooks"
import { setAdvancedControlsSheetVisible } from "features/insights/insightsSlice"

const ViewInsightsButton = () => {
  const dispatch = useAppDispatch()

  return (
    <Button
      small={true}
      text="View insights"
      onClick={() => dispatch(setAdvancedControlsSheetVisible(true))}
    />
  )
}

export default ViewInsightsButton
