import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { listConversations } from "app/api"
import { doc, getDoc } from "app/firebase"
import {
  IConversation,
  IConversationResponse,
  IConversationReviewMin,
  KeyValueType,
  market,
} from "app/types"
import { entityRow, ListConversationState, MosaikViewLabel } from "./types"
import { getEntitiesZip } from "./api"

const initialState: ListConversationState = {
  loadingConversations: false,
  totalAmountOfConversations: 0,
  filters: [],
  labels: {},
  loadingLabels: false,
  entitiesLoading: false,
  filterMenuOpen: false,
}

export const fetchConversations = createAsyncThunk(
  "listConversation/fetchConversations",
  async (filters: KeyValueType[]) => {
    return (await listConversations(filters)).data
  },
)

export const loadEntitiesZipAsync = createAsyncThunk(
  "listConversation/loadEntitiesZipAsync",
  async ({ inputMarket }: { inputMarket: market }) => {
    return await getEntitiesZip(inputMarket)
  },
)

export const fetchLabels = createAsyncThunk(
  "listConversation/fetchLabels",
  async () => {
    const labelsRef = doc("config", "labels")
    const labelsDoc = await getDoc(labelsRef)

    if (!labelsDoc?.data) {
      return {}
    }

    const labelsObject: Record<number, MosaikViewLabel> = {}
    labelsDoc.data.labels.forEach((label: MosaikViewLabel) => {
      labelsObject[label.id] = label
    })

    return labelsObject
  },
)

export const listConversationSlice = createSlice({
  name: "listConversation",
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<IConversation[]>) => {
      state.conversations = action.payload
    },
    setSelectedConversation: (state, action: PayloadAction<IConversation>) => {
      state.selectedConversation = action.payload
    },
    setFilters: (state, action: PayloadAction<KeyValueType[]>) => {
      state.filters = action.payload
    },
    updateFlagNames: (
      state,
      action: PayloadAction<{ conversationId: string; flagNames: string[] }>,
    ) => {
      if (!state.conversations) return
      const conversationIndex = state.conversations.findIndex(
        (c) => c.id === action.payload.conversationId,
      )

      state.conversations[conversationIndex].flagNames =
        action.payload.flagNames
    },
    updateReview: (
      state,
      action: PayloadAction<{
        conversationId: string
        correctLabelId?: number
        comment?: string
      }>,
    ) => {
      if (!state.conversations) return
      const conversationIndex = state.conversations.findIndex(
        (c) => c.id === action.payload.conversationId,
      )
      if (action.payload.correctLabelId) {
        const conversationReview: IConversationReviewMin = {
          correctLabelId: action.payload.correctLabelId,
          comment: action.payload.comment ?? null,
        }
        state.conversations[conversationIndex].review = conversationReview
      } else {
        state.conversations[conversationIndex].review = undefined
      }
    },
    resetErrorMessage: (state) => {
      state.errorMessage = undefined
    },
    setEntities: (state, action: PayloadAction<entityRow[]>) => {
      state.entities = action.payload
    },
    toggleFilterMenu: (state) => {
      state.filterMenuOpen = !state.filterMenuOpen
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-vars
    builder.addCase(fetchConversations.pending, (state) => {
      if (state.loadingConversations === false) {
        state.loadingConversations = true
      }
    })
    builder.addCase(
      fetchConversations.fulfilled,
      (state, action: PayloadAction<IConversationResponse>) => {
        state.conversations = action.payload.conversations ?? []
        state.totalAmountOfConversations =
          action.payload.totalConversationAmount
        state.loadingConversations = false
        state.errorMessage = undefined
      },
    )
    builder.addCase(fetchConversations.rejected, (state, action) => {
      if (state.loadingConversations === true) {
        state.loadingConversations = false
        state.conversations = []
        state.errorMessage = action.error.message
      }
    })
    builder.addCase(fetchLabels.pending, (state) => {
      if (state.loadingLabels === false) {
        state.loadingLabels = true
      }
    })
    builder.addCase(
      fetchLabels.fulfilled,
      (state, action: PayloadAction<Record<number, MosaikViewLabel>>) => {
        state.labels = action.payload
        state.loadingLabels = false
      },
    )
    builder.addCase(fetchLabels.rejected, (state) => {
      if (state.loadingLabels === true) {
        state.loadingLabels = false
      }
    })
    builder.addCase(loadEntitiesZipAsync.pending, (state) => {
      if (!state.entitiesLoading) {
        state.entitiesLoading = true
      }
    })
    builder.addCase(loadEntitiesZipAsync.fulfilled, (state, action) => {
      state.entitiesLoading = false
      state.entitiesZip = action.payload
    })
    builder.addCase(loadEntitiesZipAsync.rejected, (state) => {
      if (state.entitiesLoading) {
        state.entitiesLoading = false
      }
    })
  },
})

export const {
  setConversations,
  setSelectedConversation,
  setFilters,
  updateReview,
  resetErrorMessage,
  updateFlagNames,
  toggleFilterMenu,
} = listConversationSlice.actions

export default listConversationSlice.reducer
