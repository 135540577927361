import Button from "@ingka/button"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setChatHistoryVisibility } from "features/insights/insightsSlice"

const HistoryButton = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  
  return (
    <div className="subtle-secondary" style={{ whiteSpace: "nowrap" }}>
      <Button
        type="secondary"
        small={true}
        onClick={() => dispatch(setChatHistoryVisibility(!insightsState.chatHistoryVisible))}
      >
        History
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="12.5" r="12.5" fill="#F5F5F5" />
          <path
            d="M12.5 11.4583L8.5069 15.4513L7.29163 14.236L12.5 9.02771L17.7083 14.236L16.493 15.4513L12.5 11.4583Z"
            fill="#B8B8B8"
          />
        </svg>
      </Button>
    </div>
  )
}

export default HistoryButton
