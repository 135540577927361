import { ChatViewProps, PromptControlProps } from "features/insights/types"
import SendMessageButton from "../buttons/SendMessageButton"
import ShowAdvancedButton from "../buttons/ShowAdvancedButton"
import { useRef, useState } from "react"
import AdvancedViewToggle from "./AdvancedViewToggle"
import { setCurrentMessage } from "features/insights/insightsSlice"
import he from "he"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { LinearProgress } from "@mui/material"
import HistoryButton from "../buttons/HistoryButton"

interface ChatViewOverlayProps extends ChatViewProps, PromptControlProps {}

const ChatViewOverlay = ({
  chatControlsRef,
  promptCrud,
}: ChatViewOverlayProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const [lineCount, setLineCount] = useState(1)

  const handleInput = () => {
    if (textareaRef.current) {
      const newLineCount = textareaRef.current.value.split("\n").length

      if (newLineCount !== lineCount) {
        setLineCount(newLineCount)

        if (newLineCount > 1) {
          textareaRef.current.style.height = "auto"
          textareaRef.current.style.height = `calc(${textareaRef.current.scrollHeight}px - 16px)`
        } else {
          textareaRef.current.style.height = ""
        }
      }
    }
  }

  const handleParentClick = () => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }

  const messageLoading =
    insightsState.insightsSummaryLoading || insightsState.insightsLoading

  return (
    <div className="sticky-overlay">
      <div className="chat-controls-parent">
        <div
          style={{
            width: "calc(128rem / 16)",
            pointerEvents: "all",
          }}
        >
          <HistoryButton />
        </div>

        <div
          className="chat-controls"
          ref={chatControlsRef}
          onClick={handleParentClick}
        >
          {messageLoading ? (
            <div className="chat-input-loading">
              <LinearProgress color="secondary" />
            </div>
          ) : (
            <textarea
              ref={textareaRef}
              onInput={handleInput}
              className="chat-input"
              value={he.decode(insightsState.currentChatMessage.prompt)}
              placeholder="Write a prompt"
              onChange={(e) =>
                dispatch(setCurrentMessage(he.encode(e.target.value)))
              }
            />
          )}
          <div
            className="flex-row spaced"
            style={{
              padding: "0 1rem",
              width: "calc(100% - 2rem)",
            }}
          >
            <div>{promptCrud}</div>
            <div>
              <div className="flex-row">
                <ShowAdvancedButton />
                <SendMessageButton />
              </div>
            </div>
          </div>
        </div>

        <div style={{ pointerEvents: "all" }}>
          <AdvancedViewToggle />
        </div>
      </div>
    </div>
  )
}

export default ChatViewOverlay
