import "@ingka/accordion/dist/style.css"
import "@ingka/banner/dist/style.css"
import "@ingka/button/dist/style.css"
import "@ingka/choice/dist/style.css"
import "@ingka/focus/dist/style.css"
import "@ingka/loading/dist/style.css"
import "@ingka/pill/dist/style.css"
import "@ingka/search/dist/style.css"
import "@ingka/svg-icon/dist/style.css"
import "@ingka/switch/dist/style.css"
import "@ingka/text/dist/style.css"
import "@ingka/toggle/dist/style.css"
import "@ingka/hyperlink/dist/style.css"
import "@ingka/forms/dist/style.css"
import "@ingka/slider/dist/style.css"
import "@ingka/helper-text/dist/style.css"
import "@ingka/inline-message/dist/style.css"
import "@ingka/table/dist/style.css"
import "@ingka/carousel/dist/style.css"
import "@ingka/tabs/dist/style.css"
import '@ingka/modal/dist/style.css'

import "i18n.ts"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App.tsx"
import { store } from "./app/store.ts"
import "./index.css"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
)
