import { Grid } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import InfoCard from "components/InfoCard"
import PromptCrud from "./PromptCrud"
import { PromptType, SavedPrompt, SheetViewProps } from "./types"
import { useEffect, useRef } from "react"
import { setAdvancedControlsSheetVisible, setAnimateButton, setChatHistoryVisibility } from "./insightsSlice"
import { InsightsUtilsRunners } from "./utils/llmUtilRunners"
import InsightsButton from "./components/buttons/InsightsButton"
import SummaryButton from "./components/buttons/SummaryButton"
import ChatView from "./components/views/ChatView"
import AdvancedView from "./components/views/AdvancedView"
import ChatViewOverlay from "./components/misc/ChatViewOverlay"
import SheetView from "./components/views/SheetView"
import HideAdvancedButton from "./components/buttons/HideAdvancedButton"
import AdvancedViewDetails from "./components/views/AdvancedViewDetails"
import HistoryView from "./components/views/HistoryView"
// import { InsightPrompt } from "./costUtils/costUtils"

// const { conversationsCost, insightsCost, summaryCost } = useCostUtils(
//   insightsState.selectedInsightPrompt,
//   insightsState.selectedSummaryPrompt,
//   conversations,
// );

// function displayCost(
//   cost: number,
//   model: InsightPrompt["model"],
// ): JSX.Element | null {
//   if (model === "GPT-4o Mini" || model === "GPT-4o") {
//     return (
//       <p style={{ color: "#777", fontSize: ".75rem" }}>
//         Estimated cost of prompt:{" "}
//         {cost > 0.5 ? (
//           <>
//             <span style={{ fontWeight: "600", color: "rgb(174, 19, 3)" }}>
//               {cost.toFixed(6)} USD
//             </span>{" "}
//             (we recommend using <b>GPT4o Mini</b> for the prompt).
//           </>
//         ) : cost > 0.1 && cost <= 0.5 ? (
//           <span style={{ fontWeight: "600", color: "rgb(174, 148, 3)" }}>
//             {cost.toFixed(6)} USD
//           </span>
//         ) : (
//           <span style={{ fontWeight: "600", color: "rgb(7, 140, 28)" }}>
//             {cost.toFixed(6)} USD
//           </span>
//         )}
//       </p>
//     )
//   }
//   return null
// }

interface ConversationInsightsProps {
  editorMode?: boolean
  insightsPrompts?: SavedPrompt[]
  summaryPrompts?: SavedPrompt[]
  onPromptSave: (selectedPrompt: SavedPrompt, promptType: PromptType) => void
  onAddPrompt: (prompt: SavedPrompt, promptType: PromptType) => void
  onPromptDelete: (promptId: string, promptType: PromptType) => void
}

const ConversationInsights: React.FC<ConversationInsightsProps> = ({
  editorMode = false,
  insightsPrompts,
  summaryPrompts,
  onPromptSave,
  onAddPrompt,
  onPromptDelete,
}) => {
  const dispatch = useAppDispatch()

  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const insightsState = useAppSelector((state: RootState) => state.insights)

  const { runDefaultData } = InsightsUtilsRunners()

  const scrollRef = useRef<HTMLDivElement | null>(null)
  const prevChatMessagesLength = useRef(insightsState.chatMessages.length)
  const chatControlsRef = useRef<HTMLDivElement | null>(null)
  const hasAppliedInitialData = useRef(false)

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }

  // Insert the default data sample into the chat
  useEffect(() => {
    conversations &&
      insightsState.chatMessages.length === 0 &&
      !hasAppliedInitialData.current &&
      runDefaultData(hasAppliedInitialData)
  }, [insightsState.chatMessages, conversations, dispatch])

  // Handle resize of chat height on chatMessages change
  useEffect(() => {
    if (insightsState.chatMessages.length > prevChatMessagesLength.current) {
      if (!scrollRef.current) return

      const resizeObserver = new ResizeObserver(scrollToBottom)
      resizeObserver.observe(scrollRef.current)
      scrollToBottom()
      prevChatMessagesLength.current = insightsState.chatMessages.length
    } else if (
      insightsState.chatMessages.length < prevChatMessagesLength.current
    ) {
      prevChatMessagesLength.current = insightsState.chatMessages.length
    }
  }, [insightsState.chatMessages, scrollRef])

  // Handle advanced insights button animation
  useEffect(() => {
    if (insightsState.animateButton) {
      const timer = setTimeout(() => {
        dispatch(setAnimateButton(false))
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [insightsState.animateButton])

  const prePromptCrud = (
    <PromptCrud
      prompts={insightsPrompts}
      promptType="insight"
      promptAction={<InsightsButton />}
      onAddPrompt={onAddPrompt}
      onPromptSave={onPromptSave}
      onPromptDelete={onPromptDelete}
    />
  )

  const summaryPromptCrud = (
    <PromptCrud
      prompts={summaryPrompts}
      promptType="summary"
      promptAction={<SummaryButton />}
      onAddPrompt={onAddPrompt}
      onPromptSave={onPromptSave}
      onPromptDelete={onPromptDelete}
    />
  )

  const advancedViewVisible = insightsState.advancedControlsSheetVisible

  const advancedView: SheetViewProps = {
    visible: advancedViewVisible,
    handleClose: () => dispatch(setAdvancedControlsSheetVisible(false)),
    content: <AdvancedView promptCrud={prePromptCrud} />,
    footer: (
      <div
        className="flex-row"
        style={{ margin: "0 0 2rem 2.5rem", width: "calc(100% - 5rem)" }}
      >
        <div style={{ flex: 1 }}>
          <InsightsButton />
        </div>
        <div style={{ flex: 1 }}>
          <HideAdvancedButton />
        </div>
      </div>
    ),
    details: <AdvancedViewDetails />,
  }

  const historyViewVisible = insightsState.chatHistoryVisible

  const historyView: SheetViewProps = {
    visible: historyViewVisible,
    handleClose: () => dispatch(setChatHistoryVisibility(false)),
    content: <HistoryView />,
    footer: <></>,
    details: <></>,
  }

  const insightsPageDisabled =
    !editorMode && (!conversations || conversations.length === 0)

  return insightsPageDisabled ? (
    <Grid container justifyContent="center" alignItems="center" height="90vh">
      <InfoCard
        text="You have no conversations to get insights on."
        header="No conversations"
      />
    </Grid>
  ) : (
    <>
      <SheetView {...historyView} />
      <SheetView {...advancedView} />
      <ChatViewOverlay
        chatControlsRef={chatControlsRef}
        promptCrud={summaryPromptCrud}
      />
      <ChatView
        chatControlsRef={chatControlsRef}
        hasAppliedInitialData={hasAppliedInitialData}
      />
    </>
  )
}

export default ConversationInsights
