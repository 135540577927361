import Header from "components/Header"
import LoadingView from "components/Loading"
import { Navigate, useLocation } from "react-router-dom"
import { useAppSelector } from "./hooks"
import { RootState } from "./store"

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  let location = useLocation()

  const { user, loading, checkingPermissions, isAdmin } = useAppSelector(
    (state: RootState) => state.auth,
  )

  if (loading || checkingPermissions) {
    return <LoadingView />
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  if (!isAdmin) {
    return <Navigate to="/" state={{ from: location }} />
  }

  return (
    <div className="page">
      <Header />
      <div className="header-padding" />
      <div className="page-contents">{children}</div>
    </div>
  )
}

export default AdminRoute
