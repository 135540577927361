import Toggle from "@ingka/toggle"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import {
  setTabIndex,
} from "features/insights/insightsSlice"

const AdvancedViewToggle = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)

  const manuallyConfiguredInsights = !(
    Object.keys(insightsState.insights).length === 0
  )

  const isDisabled = insightsState.insightsLoading

  const styleDisabled: React.CSSProperties = {
    pointerEvents: "none",
    cursor: "default",
    borderColor: "rgb(204, 204, 204))",
    color: "rgb(204, 204, 204)",
  }

  return (
    <div style={isDisabled ? styleDisabled : {}}>
      <Toggle
        iconOnly={false}
        disabled={insightsState.insightsLoading}
        buttons={[
          {
            text: "Chat",
          },
          {
            text: "Insights",
            disabled: !manuallyConfiguredInsights,
          },
        ]}
        fluid={true}
        activeIndex={insightsState.tabIndex}
        onClick={(_eventObj, index) => {
          dispatch(setTabIndex(index))
        }}
      />
    </div>
  )
}

export default AdvancedViewToggle
