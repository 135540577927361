import { useState } from "react"
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Menu,
  TextField,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { PromptType, SavedPrompt } from "./types"
import { setSelectedPrompt } from "./insightsSlice"
import Add from "@mui/icons-material/Add"
import Delete from "@mui/icons-material/Delete"

interface PromptCrudProps {
  prompts?: SavedPrompt[]
  promptType: PromptType
  promptAction: JSX.Element
  onPromptSave: (selectedPrompt: SavedPrompt, promptType: PromptType) => void
  onAddPrompt: (prompt: SavedPrompt, promptType: PromptType) => void
  onPromptDelete: (promptId: string, promptType: PromptType) => void
}

const PromptCrud: React.FC<PromptCrudProps> = ({
  prompts,
  promptType,
  onPromptSave,
  onAddPrompt,
  onPromptDelete,
}) => {
  const dispatch = useAppDispatch()
  const { isSaving } = useAppSelector((state: RootState) => state.insights)
  const selectedPrompt = useAppSelector((state: RootState) =>
    promptType === "insight"
      ? state.insights.selectedInsightPrompt
      : state.insights.selectedSummaryPrompt,
  )
  const isSaved = useAppSelector((state: RootState) =>
    promptType === "insight"
      ? state.insights.insightPromptIsSaved
      : state.insights.summaryPromptIsSaved,
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [formOpen, setFormOpen] = useState(false)
  const [displayName, setDisplayName] = useState("")
  const [saveAsNewOpen, setSaveAsNewOpen] = useState(false)
  const [saveAsNewName, setSaveAsNewName] = useState("")

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const promptSelected = (prompt: SavedPrompt) => {
    dispatch(setSelectedPrompt({ prompt, promptType }))
    handleMenuClose()
  }

  const addPrompt = () => {
    if (!displayName) return
    onAddPrompt(
      {
        displayName,
        prompt: "",
        id: "",
        amount: 20,
        model: "GPT-4o Mini",
      },
      promptType,
    )
    setFormOpen(false)
    setDisplayName("")
  }

  const removePrompt = (promptId: string) => {
    onPromptDelete(promptId, promptType)
  }

  const savePrompt = () => {
    onPromptSave(selectedPrompt, promptType)
  }

  const saveAsNewPrompt = () => {
    if (!saveAsNewName) return

    const promptBody: SavedPrompt = {
      ...selectedPrompt,
      displayName: saveAsNewName,
      id: "",
    }
    onAddPrompt(promptBody, promptType)
    setSaveAsNewOpen(false)
    setSaveAsNewName("")
  }

  return (
    <>
      <div>
        {!(isSaving || isSaved) ? (
          <div
            className="flex-row prompt-crud"
            style={{ width: "100%", marginBottom: "2rem" }}
          >
            <Button disabled={isSaving || isSaved} onClick={savePrompt}>
              Update prompt
            </Button>
            {!saveAsNewOpen && (
              <Button
                disabled={isSaving || isSaved}
                onClick={() => setSaveAsNewOpen(true)}
              >
                Save as new Prompt
              </Button>
            )}
          </div>
        ) : promptType === "insight" && (isSaving || isSaved) ? <div
        className="flex-row prompt-crud"
        style={{ width: "100%", marginBottom: "4rem" }}
      ></div> : ""}
        <div className="flex-row">
          <Button
            onClick={handleMenuOpen}
            variant="contained"
            className="selected-prompt"
          >
            {selectedPrompt ? "Selected: " + selectedPrompt.displayName : "Select Prompt"}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <Box width={400} padding={1}>
              <Box display="flex" alignItems="center" mb={1}>
                <IconButton onClick={() => setFormOpen(!formOpen)}>
                  <Add />
                </IconButton>
                {formOpen && (
                  <>
                    <TextField
                      size="small"
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                      placeholder="Enter name"
                      inputProps={{ maxLength: 50 }}
                    />
                    <Button
                      onClick={addPrompt}
                      disabled={prompts && prompts.length >= 10}
                    >
                      Add
                    </Button>
                  </>
                )}
              </Box>
              <List>
                {prompts?.map((prompt) => (
                  <ListItem
                    key={prompt.id}
                    secondaryAction={
                      <IconButton
                        disabled={isSaving}
                        edge="end"
                        onClick={() => removePrompt(prompt.id)}
                      >
                        <Delete />
                      </IconButton>
                    }
                    onClick={() => promptSelected(prompt)}
                    selected={selectedPrompt.id === prompt.id}
                  >
                    <ListItemButton>{prompt.displayName}</ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Menu>
        </div>
        {saveAsNewOpen && (
          <>
            <TextField
              size="small"
              value={saveAsNewName}
              onChange={(e) => setSaveAsNewName(e.target.value)}
              placeholder="Enter name"
              inputProps={{ maxLength: 50 }}
            />
            <Button
              onClick={saveAsNewPrompt}
              disabled={prompts && prompts.length >= 10}
            >
              Add
            </Button>
            <Button onClick={() => setSaveAsNewOpen(false)}>Cancel</Button>
          </>
        )}
      </div>
    </>
  )
}

export default PromptCrud
